import pickBy from 'lodash/pickBy'
// NON-MUTATING ARRAY OPERATORS:
// ------------------------------------------------------------------------------------
// non-mutating reordering of array
// returns copy of array with item at index moved to newIndex
const reorderedArray = (array, index, newIndex) => {
  // copy
  const arrayCopy = [...array]
  // remove object at index
  const [gettingMoved] = arrayCopy.splice(index, 1)
  // put it back in at new index
  arrayCopy.splice(newIndex, 0, gettingMoved)
  return arrayCopy
}

// non-mutating delete of item at index in array.
// returns modified copy of array without item at index
const arrayWithoutItemAtIndex = (array, index) => {
  // copy
  const arrayCopy = [...array]
  // remove object at index
  arrayCopy.splice(index, 1)
  return arrayCopy
}

// non-mutating update of object in array
// returns copy of array with requested value for key of object at index
// eg
// const array = [{a: 1, b:2}, {c:3, d:5}]
// const index = 1
// const key = 'd'
// const value = 4
// arrayWithUpdatedObject(array, index, key, value)
// [ { a: 1, b: 2 }, { c: 3, d: 4 } ]
const arrayWithUpdatedObject = (array, index, key, value) => {
  const arrayCopy = [...array]
  array[index][key] = value
  return arrayCopy
}

// UUID / IDENTIFIERS:
// ------------------------------------------------------------------------------------
// https://stackoverflow.com/questions/105034/how-to-create-guid-uuid/2117523#2117523
// a shitty uuid in a compact implementation
// don't need real uuid-grade uniqueness so this should be fine
const pseudoUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

// - DATE FORMATTING
// ------------------------------------------------------------------------------------
const shortMonthNumericYearDateFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'short'});
const longMonthNumericYearDateFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long'});

const dateToShortMonthNumericYearString = (date) => {
  return shortMonthNumericYearDateFormat.format(date)
}

const shortMonthNumericYearDateStringToDate = (string) => {
  return new Date(string)
}
// monthFormat can be 'short' (eg 'Jan') or 'long' (eg 'January')
// defaults to long
const datesString = (startDate, endDate, isCurrent, monthFormat) => {
  const dateTimeFormat = monthFormat === 'short' ? shortMonthNumericYearDateFormat : longMonthNumericYearDateFormat
  const endDateString = isCurrent ? 'Present' : (endDate ? dateTimeFormat.format(endDate) : '')
  const datesString = `${dateTimeFormat.format(startDate)} — ${endDateString}`
  return datesString
}

// throttle a function, 'callback', to once per 'limit' milliseconds
const throttle = (callback, limit) => {
    var waiting = false;                      // Initially, we're not waiting
    return function () {                      // We return a throttled function
        if (!waiting) {                       // If we're not waiting
            callback.apply(this, arguments);  // Execute users function
            waiting = true;                   // Prevent future invocations
            setTimeout(function () {          // After a period of time
                waiting = false;              // And allow future invocations
            }, limit);
        }
    }
}

const buildURL = (url, params) => {
  const filteredParams = pickBy(params, (o) => o !== undefined)
  const searchParams = new URLSearchParams(filteredParams)
  return `${url}?${searchParams.toString()}`
}

const concatFirstAndLastNames = ({firstName, lastName}) => {
  const _firstName = firstName || ''
  const _lastName = lastName || ''
  if (_firstName && _lastName) {
    return `${firstName} ${lastName}`
  }
  return firstName || lastName
}

export {
  reorderedArray, arrayWithoutItemAtIndex, arrayWithUpdatedObject,
  pseudoUuid,
  shortMonthNumericYearDateFormat, dateToShortMonthNumericYearString, shortMonthNumericYearDateStringToDate, datesString,
  throttle,
  buildURL,
  concatFirstAndLastNames,
}

import React, { useState, useEffect, useRef } from 'react';
import {navigate, Link} from 'gatsby'
import SEO from "src/components/seo"
import AnalyticsHelmet from 'src/components/AnalyticsHelmet'
import SupportHelmet from 'src/components/SupportHelmet'
import withSize from 'src/utilities/withSize'
import { trackPage, identify, trackEvent, } from 'src/utilities/analytics'
import { useURLParamToTrackEvent } from 'src/utilities/hooks'
import {makeSafeProfile} from 'src/utilities/safe-profile'
import { renderResumeToPDF } from 'src/utilities/render-pdf'
import PDFViewer from 'src/components/shared/PDFViewer'
import { getResume, } from 'src/utilities/firestore'
import getFirebase from 'src/utilities/firebase'
import Paginator from 'src/components/shared/Paginator'
import Logo from 'src/images/diy-logo.png'
import { concatFirstAndLastNames } from 'src/utilities/functions'

const pageName = 'Shared'

const extractResumeId = (pathname) => {
  const components = pathname.split('/')
  const filtered = components.filter((c) => c.length > 0)
  return filtered[1]
}

const HomeButton = () => {
  return (
    <Link to='/?referring_page=shared&utm_source=shared_resume' style={ styles.homeButton }>
      <img src={Logo} style={ styles.logo }/>
      NextLevelResume.co
    </Link>
  )
}

const Container = ({children}) => {
  return (
    <div style={styles.container}>
      {children}
    </div>
    )
}

const PDFViewerContainer = ({
  file,
  style,
  size,
  pageNumber,
  setNumPages,
}) => {

  const {
    width,
    height,
   } = size

   // we like 900 page width
   // as screen gets smaller than that, scale down pdf with screen
   // with horizontal margins set for larger screens, and mobile
   let pageWidth = 900
   const paddingHorizontal = 20
   const paddingHorizontalMobile = 10

   if (width <= 576) {
     pageWidth = width - 2 * paddingHorizontalMobile
   } else if (width < 900 + 2 * paddingHorizontal) {
     pageWidth = width - 2 * paddingHorizontal
   }

  return (
    <div style={styles.pdfViewerContainer}>
      <PDFViewer
        {...{file, size, pageWidth, pageNumber, setNumPages}}
      />
    </div>
  )
}

const SharedResumesShow = ({ location, }) => {
  const resumeId = extractResumeId(location.pathname)

  const [pageNumber, setPageNumber] = useState(1)
  const [numPages, setNumPages] = useState(1) // numPages gets set in pdf viewer

  const previousButtonEnabled = pageNumber > 1
  const nextButtonEnabled = pageNumber < numPages

  const onClickPreviousPage = () => {
    setPageNumber(pageNumber - 1)
  }
  const onClickNextPage = () => {
    setPageNumber(pageNumber + 1)
  }

  // - PROFILE
  const [profile, setProfile] = useState(makeSafeProfile({}))
  const firebase = getFirebase();

  // - BlOB URL
  const [blobURL, setBlobURL] = useState(null)

  useEffect(() => {
    if (!firebase) return;

    getResume({resumeId})
    .then(resume => {
      setProfile(resume)
    })
    .catch(error => {
      console.error(error)
      navigate('/404')
    })

  }, [firebase, resumeId]);

  useEffect(() => {
    if (profile) {
      renderResumeToPDF({profile})
      .then(({blob, blobURL}) => {
        setBlobURL(blobURL)
      })
    }
  }, [profile])

  useEffect(() => {
    trackPage(pageName)
  }, [])

  const {
    personalDetails: {
      firstName,
      lastName,
    }
  } = profile

  const fullName = concatFirstAndLastNames({
    firstName,
    lastName,
  })

  const title = (firstName || lastName) ? `${fullName}'s Resume` : 'Shared Resume'

  return (
    <Container>
      <SEO title={title} />
      <AnalyticsHelmet/>
      <SupportHelmet/>
      <HomeButton/>
      <PDFViewerContainerWithSize
         file={blobURL}
         style={styles.pdfViewer}
         {...{ pageNumber, setNumPages }}
       />
       <Paginator {...{pageNumber, numPages, onClickPreviousPage, onClickNextPage, previousButtonEnabled, nextButtonEnabled}} style={styles.paginator}/>
    </Container>
    )
}

const PDFViewerContainerWithSize = withSize(PDFViewerContainer)

const styles = {
  container: {
    display: 'flex',
    width: '100vw',
    minHeight: '100vh',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgb(122, 133, 153)',
  },
  pdfViewerContainer: {
    display: 'flex',
    width: '100%',
    minHeight: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 40,
  },
  pdfViewer: {
  },
  paginator: {
    position: 'fixed',
    bottom: 24,
  },
  logo: {
    width: 30,
    marginRight: 10
  },
  homeButton: {
    height: 50,
    marginTop: 10,
    marginBottom: 10,
    textDecoration: 'none',
    color: 'white',
    fontSize: '1.25rem',
    fontWeight: 600,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}

export default SharedResumesShow

import React from "react"
import { Router, Redirect } from "@reach/router"
import SharedResumesShow from 'src/components/resumes/shared'

const SharedResumesRouter = () => {
  return (
    <Router basepath='/shared'>
      <SharedResumesShow path='/:resume_id'/>
      <Redirect noThrow from="/" to="/404" />
    </Router>
    )
}

export default SharedResumesRouter
